import "./index.scss";
import { Link } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context";

const Footer = () => {
    const { resumo, language } = useContext(Context);
    return (
        <div id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-4">
                        <a href="https://www.gelcointernational.com/" target={"_blank"} rel="noopener noreferrer" title="Gelco International">
                            <img className="logo" src={`${localStorage.getItem("apiUrl")}imagens/medium/logo_inter.svg`} alt="Logo" />
                        </a>
                        <div className="ad">
                            <p className="p3" translate={resumo.textos[`footer_grupo${language}`] && resumo.textos[`footer_grupo${language}`].length > 1 ? "no" : "yes"} dangerouslySetInnerHTML={{ __html: resumo.textos[`footer_grupo${language}`] && resumo.textos[`footer_grupo${language}`].length > 1 ? resumo.textos[`footer_grupo${language}`] : resumo.textos[`footer_grupo`] }}></p>
                        </div>
                        <div className="socials">
                            {resumo.configuracoes.facebook && resumo.configuracoes.facebook.length > 1 &&
                                <a class="social-wrapper" href={resumo.configuracoes.facebook} target="_blank" rel="noopener noreferrer" title="Facebook">
                                    <div>
                                        <img src={`${localStorage.getItem("apiUrl")}imagens/medium/facebook.svg`} alt="Facebook" />
                                    </div>
                                </a>
                            }
                            {resumo.configuracoes.instagram && resumo.configuracoes.instagram.length > 1 &&
                                <a class="social-wrapper" href={resumo.configuracoes.instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                    <div>
                                        <img src={`${localStorage.getItem("apiUrl")}imagens/medium/instagram.svg`} alt="Instagram" />
                                    </div>
                                </a>
                            }
                            {resumo.configuracoes.linkedin && resumo.configuracoes.linkedin.length > 1 &&
                                <a class="social-wrapper" href={resumo.configuracoes.linkedin} target="_blank" rel="noopener noreferrer" title="Linkedin">
                                    <div>
                                        <img src={resumo.configuracoes.imagens.linkedin.medium} alt="Linkedin" />
                                    </div>
                                </a>
                            }
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="menu">
                            <h2 className="h5">Menu</h2>
                            <div className="items">
                                <Link to="/" className="p2 txt-default" title="Home">Home</Link>
                                <Link to="/empresa" className="p2 txt-default" title="Empresa">Empresa</Link>
                                {resumo.produtos && <Link key={`menu-footer-produtos-${resumo.produtos[0].slug}`} to={`/produto/${resumo.produtos[0].slug}`} className="p2 txt-default" title={resumo.produtos[0].nome}>Produtos</Link>}
                                <Link to="/blog" className="p2 txt-default" title="Blog">Blog</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="menu">
                            <h2 className="h5">Links</h2>
                            <div className="items">
                                <Link to="/fale-conosco" className="p2 txt-default" title="Fale conosco">Fale conosco</Link>
                                <Link to="/trabalhe-conosco" className="p2 txt-default" title="Trabalhe conosco">Trabalhe conosco</Link>
                                {resumo.documentos && resumo.documentos.map(documento => <a href={documento.arquivo} className="p2 txt-default" title={documento.nome} target="_blank" rel="noopener noreferrer">{documento.nome}</a>)}
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="menu">
                            <h2 className="h5">Contato</h2>
                            <div className="endereco">
                                <p className="p2">{resumo.configuracoes.endereco} - {resumo.configuracoes.bairro}<br />{resumo.configuracoes.cidade} - {resumo.configuracoes.estado}, Brasil, CEP {resumo.configuracoes.cep}</p>
                                <p className="p2">Telefone: {resumo.configuracoes.telefone}</p>
                                <p className="p2">E-mail: {resumo.configuracoes.email}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creditos">
                    <p className="p3 txt-default">
                        GelcoPEP - {resumo.configuracoes.cnpj} - Todos os direitos Reservados © {new Date().getFullYear()}
                    </p>
                    <a className="p3 txt-default" href="https://fw2propaganda.com.br/" rel="noopener noreferrer" title="Desenvolvido por Gustavo Barbosa" target={"_blank"}>Site desenvolvido por <img src={`${localStorage.getItem("apiUrl")}imagens/medium/creditos.svg`} alt="FW2 Propaganda" /></a>
                </div>
            </div>
        </div>
    )
}

export default Footer;