import "./index.scss";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import Context from "../../Context";

const Header = ({ setLanguage, getCookie }) => {
    const { resumo } = useContext(Context);
    const [menu, setMenu] = useState(false);

    return (
        <div id="header">
            <div className="container">
                <nav>
                    <Link to="/" title="Home">
                        <img src={`${localStorage.getItem("apiUrl")}imagens/medium/logo.svg`} alt="Logo" />
                    </Link>
                    <button className="toggleMenu" type="button" onClick={() => setMenu(!menu)}>
                        <img src={`${localStorage.getItem("apiUrl")}imagens/medium/menu.svg`} alt="Logo" />
                    </button>
                    <div className={`menu ${menu ? "active" : ""}`}>
                        <Link onClick={() => setMenu(false)} to="/" className="p2 txt-black" title="Home" translate="no">Home</Link>

                        <Link onClick={() => setMenu(false)} to="/empresa" className="p2 txt-black" title="Empresa">Empresa</Link>
                        <div className="submenu">
                            <p className="p2 txt-black" title="Produtos">Produtos</p>
                            <div>
                                {resumo.produtos && resumo.produtos.map(produto => <Link onClick={() => setMenu(false)} translate="no" key={`menu-produtos-${produto.slug}`} to={`/produto/${produto.slug}`} className="p2 txt-black" title={produto.nome}>GelcoPEP®{produto.nome}</Link>)}
                            </div>
                        </div>

                        <div className="submenu">
                            <p className="p2 txt-black" title="Estudos Clínicos">Estudos Clínicos</p>
                            <div>
                                {resumo.segmentos && resumo.segmentos.map(segmento => <Link onClick={() => setMenu(false)} translate="no" key={`menu-estudos-${segmento.slug}`} to={`/segmento/${segmento.slug}`} className="p2 txt-black" title={segmento.nome}>GelcoPEP®{segmento.nome}</Link>)}
                            </div>
                        </div>


                        {/* <Link onClick={() => setMenu(false)} to="/estudos-clinicos" className="p2 txt-black" title="Estudos Clínicos">Estudos Clínicos</Link> */}



                        <Link onClick={() => setMenu(false)} to="/blog" className="p2 txt-black" title="Blog">Blog</Link>
                        <Link onClick={() => setMenu(false)} to="/trabalhe-conosco" className="p2 txt-black" title="Trabalhe Conosco">Trabalhe Conosco</Link>
                        <Link onClick={() => setMenu(false)} to="/fale-conosco" className="p2 txt-black" title="Contato">Contato</Link>
                        <div className="languages">
                            <button type="button" className={!getCookie("googtrans") || getCookie("googtrans") === "/pt/pt" ? "active" : ""} onClick={() => setLanguage("pt")}>
                                <img src={`${localStorage.getItem("apiUrl")}imagens/medium/pt.svg`} alt="Português" />
                            </button>
                            <button type="button" className={getCookie("googtrans") === "/pt/es" ? "active" : ""} onClick={() => setLanguage("es")}>
                                <img src={`${localStorage.getItem("apiUrl")}imagens/medium/es.svg`} alt="Espanhol" />
                            </button>
                            <button type="button" className={getCookie("googtrans") === "/pt/en" ? "active" : ""} onClick={() => setLanguage("en")}>
                                <img src={`${localStorage.getItem("apiUrl")}imagens/medium/en.svg`} alt="Inglês" />
                            </button>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
};

export default Header;



